<template>
  <div class="p-3" style="height: 100%">
    <div
      class="product-single-card d-flex"
      style="
        flex-wrap: wrap;
        height: 100%;
        align-content: space-between;
        position: relative;
      "
    >
      <div class="product-img justify-content-center w-100 mt-4">
        <div class="first-view">
          <a :href="'/product/' + product.slug">
            <img
              :src="`${product.images[0].path}`"
              alt="product"
              style="object-fit: contain; width: 100%; aspect-ratio: 1"
              class=""
              onerror="this.src='/img/landing/placeholder.png'"
              loading="lazy"
            />
          </a>
        </div>
      </div>
      <div
        class="w-100 d-flex"
        style="flex-wrap: wrap; align-content: space-between;flex:1 1"
      >
        <div class="product-info text-left">
          <h6
            class="product-title d-flex align-items-center"
            style="
              
              width: 100%;
              overflow-x: hidden;
              overflow-y: hidden;
            "
          >
            <a
              style=""
              :href="'/product/' + product.slug"
              class="text-left text-lg font-weight-bold"
              >{{ product.name }}</a
            >
          </h6>

          <div clas class="d-flex flex-wrap align-items-center">
            <div
              style="
                position: absolute;
                top: 15px;
                left: 15px;
                background-color: white;
                color: #8b0000;
                font-weight: bold;
                font-size: 20px;
                z-index: 999;
              "
              class="new-price py-0 px-2 shadow-lg rounded-lg"
            >
              ${{ product.price }}
            </div>
          </div>
        </div>

        
      </div>

      <h5
        class="w-100 text-center m-0 p-0"
        style="color: #003366; font-weight: bold"
      >
      <div class="w-100">
          <div v-if="!inCart" class="text-center" style="width: 100%">
            <button class="new-add-to-cart" @click="addToCart()">
              Add to cart
            </button>
          </div>
          <div v-else class="text-center" style="width: 100%">
            <button class="new-buy-now" @click="goToCheckout()">Buy Now</button>
          </div>
        </div>
        <hr />
        <a
          class="m-0 p-0"
          style="font-weight: bold; opacity: 70%"
          :href="'/shop-category/' + product.category?.slug"
          >{{ product.category?.name }}</a
        >
      </h5>
    </div>
  </div>
</template>
<script>
import global from "@/mixins/global.js";
import { mapState } from "vuex";
import axios from "axios";
export default {
  props: ["product"],
  data() {
    return {
      loading: false,
      inCart: false,
    };
  },
  computed: {
    imagePath() {
      if (this.product.images && this.product.images.length > 0) {
        return this.product.images[0].path;
      } else {
        return "";
      }
    },
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
    productColors() {
      // console.log(this.product.properties);
      // return this.product.properties.filter(prop => prop.color)
    },
    productSizes() {
      // console.log(this.product.properties);
      // return this.product.properties.filter(prop => prop.size)
    },
  },
  mixins: [global],
  mounted() {
    // console.log("Cart", this.cart.cartItems);
    this.cart.cartItems.forEach((product) => {
      if (product.id === this.product.id) {
        this.inCart = true;
      }
    });
  },
  methods: {
    addToCart() {
      this.loading = true;
      let newProp = {
        qty: 1,
      };
      if (this.product.properties.hasOwnProperty("color")) {
        newProp["color"] = this.product.properties.color;
      }
      if (this.product.properties.hasOwnProperty("size")) {
        newProp["size"] = this.product.properties.size;
      }

      // this.$store.dispatch("cart/add", {
      //   product: this.product,
      //   quantity: 1,
      // });

      this.$store.dispatch("cart/addToCart", {
        product: this.product,
        quantity: 1,
      });
      this.inCart = true;
      // this.add(this.product, newProp);
      //this.$notification.new("Product Added To Cart", { timer: 10 });
      this.$toasted.success("Product Added To Cart");
      // setTimeout(() => {
      //   this.loading = false;
      //   location.reload();
      // }, 2000);

      let miniCartEl = document.getElementById("mini-cart");

      // if (miniCartEl && miniCartEl.length > 0) {
      //     // $('html, body').animate({scrollTop:$('#mini-cart').position().top}, 'slow');
      //     miniCartEl.style.visibility = "visible";
      //     miniCartEl.style.opacity = "1";
      //     setTimeout(() => {
      //       miniCartEl.style.visibility = "hidden";
      //       miniCartEl.style.opacity = "0";
      //     }, 3000);
      // }
    },
    goToCheckout() {
      this.$router.push({ name: "cart" });
    },
  },
};
</script>
